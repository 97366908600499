/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const translationCreateJob = /* GraphQL */ `
  mutation TranslationCreateJob(
    $input: jobNodeInput
    $limit: Int
    $nextToken: String
  ) {
    translationCreateJob(input: $input, limit: $limit, nextToken: $nextToken) {
      items {
        contentType
        createdAt
        id
        jobIdentity
        jobName
        jobOwner
        jobStatus
        languageSource
        languageTargets
        sourceKey
        sourceStatus
        translateCallback
        translateKey
        translateStatus
      }
      nextToken
    }
  }
`;
